@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playwrite+HU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+HU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
 
  margin: 0;
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family:"Amaranth", "Playwrite HU", cursive;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.sp{
  font-family: "Playwrite HU", sans-serif;
  font-weight: 200;
  font-optical-sizing: auto;
  font-style: normal;
  overflow: hidden;
  padding: 0.5em;
  
}

.end-text{
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
}

.main{
  background: rgb(222,8,8);
  background: radial-gradient(circle, rgba(222,8,8,1) 0%, rgba(121,9,9,1) 21%, rgba(0,0,0,1) 45%);
  border-bottom-left-radius: 25px; 
  border-bottom-right-radius: 25px;
  
}

.containerz{
  width: 100%;
}
